export default [
  { // 用户查询
    path: 'UserQuery',
    component: () => import('@/views/admin/Search/UserQuery.vue')
  },
  { // 用户查询详情
    path: 'UserDetail',
    meta: {
      FullScreen: true
    },
    component: () => import('@/views/admin/Search/UserDetail.vue')
  },
  { // 试卷详情
    path: 'PaperDetail',
    component: () => import('@/views/admin/Search/PaperDetail.vue')
  },
  { // 报名人员
    path: 'Applicants',
    component: () => import('@/views/admin/Search/Applicants.vue')
  },
  { // 考试人员
    path: 'Examiners',
    component: () => import('@/views/admin/Search/Examiners.vue')
  },
  { // 证书查询
    path: 'Certificate',
    component: () => import('@/views/admin/Search/Certificate.vue')
  },
  // 证书配置列表
  { 
    path: 'Templatepage',
    component: () => import('@/views/admin/Search/Templatepage.vue')
  },
  // 证书配置列表
  { 
    path: 'TemplateDetails',
    component: () => import('@/views/admin/Search/TemplateDetails.vue')
  },
  { // 用户云豆管理
    path: 'UserIntegralManager',
    component: () => import('@/views/admin/Search/UserIntegralManager.vue')
  },
  { // 用户云豆明细
    path: 'UserIntegralDetail',
    component: () => import('@/views/admin/Search/UserIntegralDetail.vue')
  },
  
]