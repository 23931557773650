// 消息管理
export default [
    // 自定义消息
    {
        path: 'custom',
        component: () =>
            import ('@/views/admin/MessageManage/custom.vue')
    },
    // 新建消息
    {
        path: 'customDetail',
        component: () =>
            import ('@/views/admin/MessageManage/customDetail.vue')
    },
    // 固定消息
    {
        path: 'fixed',
        component: () =>
            import ('@/views/admin/MessageManage/fixed.vue')
    },
    // 发送短信
    {
        path: 'sendSMS',
        component: () =>
            import ('@/views/admin/MessageManage/sendSMS.vue')
    },
    // 新建短信
    {
        path: 'newSMS',
        component: () =>
            import ('@/views/admin/MessageManage/newSMS.vue')
    },
    // 新建人工消息推送
    {
        path: 'newUserSMS',
        component: () =>
            import ('@/views/admin/MessageManage/newUserSMS.vue')
    },
    // 固定短信
    {
        path: 'fixedSMS',
        component: () =>
            import ('@/views/admin/MessageManage/fixedSMS.vue')
    },
    // 回复问题反馈
    {
        path: 'replyFeedback',
        component: () =>
            import ('@/views/admin/MessageManage/replyFeedback.vue')
    },
    // 短信管理
    {
        path: 'noteManage',
        component: () =>
            import ('@/views/admin/MessageManage/noteManage.vue')
    },
]