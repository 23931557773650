export default [
    { // 活动管理
      path: 'ActivityManager',
      component: () => import('@/views/admin/Activity/ActivityManager.vue')
    },
    { // 活动报名管理
      path: 'ActivitySignupManager',
      component: () => import('@/views/admin/Activity/ActivitySignupManager.vue')
    },
    { // 活动详情
      path: 'ActivityDetail',
      component: () => import('@/views/admin/Activity/ActivityDetail.vue')
    },
    { // 场次列表
      path: 'SessionList',
      component: () => import('@/views/admin/Activity/SessionList.vue')
    },
    { // 报名审核列表
      path: 'SignupAuditList',
      component: () => import('@/views/admin/Activity/SignupAuditList.vue')
    },
  ]